import {
  Avatar,
  Box,
  Button,
  Grid,
  Modal,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { getButtons } from 'constants/text/buttons';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
  useNavigate,
  useLocation,
  useParams,
  Link as RouterLink
} from 'react-router-dom';
import FileUploader from 'components/FileUploader';
import useLocales from 'hooks/useLocales';

import { ArrowBackIosNew, Close } from '@material-ui/icons';
import { ButtonPrimary } from 'components/new-designer/button/ButtonPrimary';
import {
  Description1,
  Description2,
  Description3,
  Title1
} from 'components/text/title';
import { USER_ROLES, getTranslatedList } from 'constants/constants';
import SvgIconStyle from 'components/SvgIconStyle';
import { useSnackbar } from 'notistack';
import { prepareFormData } from 'helpers/formFields';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { Popover } from '@mui/material';
import { ImageContainer } from 'components/ImageContainer';
import CustomDropzoneDialog from '@components/CustomDropzoneDialog';
import { ApproveDialog } from 'components/Dialog';
import { RootState } from '../../../redux/store';
import { PATH_DASHBOARD, ROOTS_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';
import { postRoom, privateRoom, roomMessage } from '../../../redux/slices/chat';
import { ProfileChat } from '../../../components/Chat';
import PublicStatus from './PublicStatus';
import { deleteDataByUrl } from '../../../requests';
import {
  postCostumerApiData,
  getCostumerApiData
} from '../../../redux/slices/customrs';
import {
  ButtonGradient,
  GridForPdf,
  Heading,
  HeadingOverlay,
  ProfileHero,
  TextFieldUrl,
  useStyles,
  useStylesDialog
} from '../../../styled';
import { patchAccountData } from '../../../redux/slices/authJwt';

const ProfileHeader = ({
  candidate,
  isEditable,
  anonymous,
  candidateAvatar,
  candidateName,
  handleUploadCV,
  openAvatarUpload,
  setOpenAvatarUpload,
  handleAnonymous,
  reportLink,
  motivatorLink,
  recruiterOrAdmin,
  downloadPDFFile,
  pdfLoading,
  type,
  isForCostumerProfile,
  customerId,
  isAnonymous,
  setIsAnonymous,
  candidateBannerImage
}: any) => {
  const { t, currentLang } = useLocales();

  const {
    UPLOAD,
    DOWNLOAD,
    UNAVAILABLE,
    CANDIDATE: {
      ASSESSMENT_REPORT,
      MOTIVATOR_REPORT,
      MAKE_PUBLIC,
      MAKE_ANONYMOUS,
      GET_URL,
      NEW_URL,
      SHARE_URL,
      FIND_JOB,
      REMOVE_URL
    },
    BACK
  } = getButtons();
  const [isBoolean, setIsBoolean] = useState(false);
  const [candidateId, setCandidateId] = useState(null);

  const [showChat, setShowChat] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [wrongToken, setWrongToken] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { pathname, search } = useLocation();
  const { id }: any = useParams();
  const { user, isCandidate, isDesigner, isEmployee } = useAuth();
  const token = localStorage.getItem('newToken');
  const dispatch = useDispatch();
  const { messages }: any = useSelector((state: RootState) => state.errors);
  const { apiData } = useSelector((state: RootState) => state.customers);
  const avatar = useSelector((state: RootState) => state.authJwt.user.avatar);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const classesDialog = useStylesDialog();
  const { FIND_BEST_MATCHES } = getButtons();
  const isRecruiterOrAdmin = useMemo(
    () => user.role === 'admin' || user.role === 'recruiter',
    [user]
  );
  const {
    DEFAULT_MESSAGE_TEXTS: { deleted }
  } = getTranslatedList();

  useEffect(() => {
    if (candidate.mainInfo.id) {
      setCandidateId(candidate.mainInfo.id);
    }
  }, [candidate.mainInfo.id]);

  useEffect(() => {
    if (messages) {
      for (let i = 0; i < messages.length; i++) {
        const element = messages[i];
        if (
          token &&
          (element?.response?.data?.detail === 'Invalid token' ||
            element?.response?.data?.detail === 'Token has expired')
        ) {
          setWrongToken(true);
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (wrongToken) {
      navigate('/404');
    }
  }, [wrongToken]);

  const generateApi = async () => {
    if (apiData?.share_url) {
      await deleteDataByUrl(
        `/api/${
          isForCostumerProfile ? 'customers' : 'candidate'
        }/${id}/share-url/${apiData.id}/`
      );
    }
    dispatch(
      postCostumerApiData(
        `/api/${
          isForCostumerProfile ? 'customers' : 'candidate'
        }/${id}/share-url/`
      )
    );
  };

  const getApi = () => {
    dispatch(
      getCostumerApiData(
        `/api/${
          isForCostumerProfile ? 'customers' : 'candidate'
        }/${id}/share-url/`
      )
    );
    setIsBoolean(!isBoolean);
  };

  useEffect(() => {
    if (
      `${ROOTS_DASHBOARD}candidates/current/${id}/?chatroom=${candidate?.mainInfo?.messenger_room_id}` ===
      pathname + search
    ) {
      setShowChat(true);
    }
  }, [candidate, id, search, pathname]);

  const openChatProfile = () => {
    navigate(
      `${PATH_DASHBOARD.candidates.getCharRoom(
        candidate.mainInfo.messenger_room_id,
        id
      )}`
    );
    if (user?.role === 'candidate' || user?.role === 'employee') {
      setShowChat(!showChat);
      roomMessage(candidate.mainInfo.messenger_room_id);
    } else if (candidate.mainInfo.messenger_room_id === null) {
      setShowChat(!showChat);
      const data = {
        candidate: id
      };
      postRoom(data);
      privateRoom(candidate.mainInfo.email);
    } else {
      setShowChat(!showChat);
      roomMessage(candidate.mainInfo.messenger_room_id);
    }
  };

  const my_profile = candidate?.mainInfo?.user === user?.id;

  const onFileSelected = async (selectedFile: any) => {
    const data = prepareFormData({}, { avatar: selectedFile });
    setOpenAvatarUpload(false);
    if (user.id) {
      dispatch(patchAccountData(user.id, data));
    }
  };

  const onReportHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    reportLink
      ? window.open(reportLink, '_blank')
      : setAnchorEl(event.currentTarget);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteDialog(!openDeleteDialog);
  };

  const handleDeleteURL = async (): Promise<void> => {
    try {
      if (apiData?.share_url) {
        await deleteDataByUrl(
          `/api/${
            isForCostumerProfile ? 'customers' : 'candidate'
          }/${id}/share-url/${apiData.id}/`
        );
        setIsBoolean(false);
        setOpenDeleteDialog(false);
        enqueueSnackbar(deleted, { variant: 'success' });
        getApi();
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleCloseChat = (): void => {
    const url = pathname.split('?');
    navigate(url[0], { replace: true });
    setShowChat(!showChat);
  };

  return (
    <Heading>
      <CustomDropzoneDialog
        open={id === 'me' && openAvatarUpload}
        onSave={onFileSelected}
        onClose={() => setOpenAvatarUpload(false)}
        prevValue={user.avatar || candidateAvatar}
      />
      <ProfileHero>
        {candidateBannerImage && (
          <ImageContainer
            style={{
              backround: 'transparent',
              height: '100%',
              width: '100%'
            }}
            src={candidateBannerImage}
          />
        )}
        {!my_profile && !token && (
          <Button
            onClick={() => navigate(-1)}
            variant="text"
            style={{
              color: '#ffffff',
              position: 'absolute',
              top: '20px',
              left: '30px'
            }}
          >
            <ArrowBackIosNew
              style={{
                fontSize: '18px',
                margin: '0 0 5px 5px'
              }}
            />
            {BACK}
          </Button>
        )}
        {!isForCostumerProfile && (
          <ButtonPrimary
            style={{
              position: 'absolute',
              right: '30px',
              top: '30px',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)'
            }}
          >
            <Description2
              style={{
                color: '#562C82'
              }}
            >
              {candidate?.mainInfo?.anonymous ? t('Anonymous') : t('Public')}
            </Description2>
          </ButtonPrimary>
        )}
      </ProfileHero>
      <Modal open={showChat} onClose={handleCloseChat}>
        <Grid
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              position: 'relative',
              width: '50%',
              height: 'max-content',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, #FFFFFF -5.2%, #FFFFFF 114.12%)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
              borderRadius: '20px',
              margin: '0 auto',
              padding: '20px'
            }}
          >
            <Typography
              style={{
                position: 'absolute',
                right: 40,
                top: 20,
                cursor: 'pointer'
              }}
              onClick={handleCloseChat}
            >
              <Close style={{ color: '#C9CAD9' }} />
            </Typography>
            <ProfileChat
              showChat={showChat}
              candidate={candidate}
              setShowChat={setShowChat}
            />
          </Box>
        </Grid>
      </Modal>
      <Modal open={showPDFModal}>
        <Grid
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'column',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              position: 'relative',
              height: 'max-content',
              margin: '0 auto',
              padding: '40px',
              background:
                'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, #FFFFFF -5.2%, #FFFFFF 114.12%)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
              borderRadius: '20px',
              maxWidth: '461px',
              width: '100%'
            }}
          >
            <Grid
              style={{
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Title1>Upload PDF </Title1>
              <CloseIcon
                style={{ color: '#C9CAD9', cursor: 'pointer' }}
                onClick={() => {
                  setShowPDFModal(false);
                  setIsAnonymous(false);
                }}
              />
            </Grid>
            <Grid
              style={{
                display: 'flex',
                rowGap: '25px',
                flexDirection: 'column'
              }}
            >
              <Description3>
                Please select the file you would like to download
              </Description3>
              <FormControlLabel
                control={<Checkbox size="medium" checked={isAnonymous} />}
                onChange={(_: SyntheticEvent, checked: boolean) =>
                  setIsAnonymous(checked)
                }
                label={t('Make anonymous')}
              />
              <GridForPdf
                disabled={pdfLoading}
                onClick={() => downloadPDFFile('all')}
              >
                <Description1 style={{ color: '#562C82' }}>
                  {t('Full Candidate Profile')}
                </Description1>
                {type === 'all' && pdfLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner" />
                  </div>
                ) : (
                  <SvgIconStyle src="/static/img/icons/downlowdViolet.svg" />
                )}
              </GridForPdf>
              <GridForPdf
                disabled={pdfLoading}
                onClick={() => downloadPDFFile('with-info')}
              >
                <Description1 style={{ color: '#562C82' }}>
                  {t('Basic Candidate Profile')}
                </Description1>
                {type === 'with-info' && pdfLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner" />
                  </div>
                ) : (
                  <SvgIconStyle src="/static/img/icons/downlowdViolet.svg" />
                )}
              </GridForPdf>
              <GridForPdf
                disabled={pdfLoading}
                onClick={() => downloadPDFFile('with-charts')}
              >
                <Description1 style={{ color: '#562C82' }}>
                  {t('Candidate Assessment Graphs ')}
                </Description1>
                {type === 'with-charts' && pdfLoading ? (
                  <div className="spinner-container">
                    <div className="loading-spinner" />
                  </div>
                ) : (
                  <SvgIconStyle src="/static/img/icons/downlowdViolet.svg" />
                )}
              </GridForPdf>
            </Grid>
          </Box>
        </Grid>
      </Modal>
      <HeadingOverlay>
        {isEditable && <PublicStatus anonymous={anonymous} />}
        <Grid>
          <Avatar
            className="candidate-avatar"
            src={user.avatar || candidateAvatar}
            onClick={() => {
              setOpenAvatarUpload(true);
            }}
          />
        </Grid>
        <Typography variant="h4" style={{ display: 'flex' }}>
          <Box className="candidate-profile-name">
            {!token ? candidateName : ''}
          </Box>
          {my_profile && candidate.mainInfo.messenger_room_id && (
            <SvgIconStyle
              onClick={openChatProfile}
              src="/static/img/icons/chat.svg"
              style={{ cursor: 'pointer' }}
            />
          )}
        </Typography>

        <Grid
          style={{ margin: '0 30px 0 auto', transform: 'translateY(-150px)' }}
        >
          {isRecruiterOrAdmin && candidateId && (
            <ButtonPrimary
              variant="contained"
              color="primary"
              component={RouterLink}
              to={PATH_DASHBOARD.jobPosts.getBestMatchesJobUrl(candidateId)}
            >
              {FIND_BEST_MATCHES}
            </ButtonPrimary>
          )}
        </Grid>
        {!token && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              margin: 'auto',
              columnGap: '40px'
            }}
          >
            {isEditable && !isEmployee && !isForCostumerProfile && (
              <FileUploader
                sx={{ mx: 1 }}
                variant="contained"
                onSubmit={handleUploadCV}
              >
                {UPLOAD} CV
              </FileUploader>
            )}
            {!isEmployee && !isForCostumerProfile && (
              <ButtonGradient
                sx={{ mx: 1 }}
                variant="contained"
                disabled={!candidate?.mainInfo?.cv}
                onClick={() =>
                  candidate?.mainInfo?.cv
                    ? window.open(candidate.mainInfo?.cv || '', '_blank')
                    : null
                }
                style={
                  !candidate.mainInfo?.cv
                    ? {
                        background:
                          'linear-gradient(92.25deg, #C5C5C5 -2.57%, #7C7C7C 113.38%)'
                      }
                    : {}
                }
              >
                {candidate.mainInfo?.cv
                  ? `${DOWNLOAD} CV`
                  : `${UNAVAILABLE} CV`}
              </ButtonGradient>
            )}
            {!isForCostumerProfile && (
              <>
                <ButtonPrimary sx={{ mx: 1 }} onClick={onReportHandler}>
                  {ASSESSMENT_REPORT}
                </ButtonPrimary>
                <Popover
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Typography sx={{ m: 1, p: 2 }}>
                    Please take the assessment first.
                  </Typography>
                </Popover>
                {recruiterOrAdmin && (
                  <ButtonPrimary
                    sx={{ mx: 1 }}
                    disabled={!motivatorLink}
                    onClick={() =>
                      motivatorLink
                        ? window.open(motivatorLink || '', '_blank')
                        : null
                    }
                  >
                    {MOTIVATOR_REPORT}
                  </ButtonPrimary>
                )}
                {isEditable && !isEmployee && (
                  <Tooltip
                    title={
                      t('By becoming anonymous you will hide your name and avatar. Recruiters will only see your professional qualities') || ''
                    }
                  >
                    <ButtonPrimary sx={{ mx: 1 }} onClick={handleAnonymous}>
                      {anonymous ? MAKE_PUBLIC : MAKE_ANONYMOUS}
                    </ButtonPrimary>
                  </Tooltip>
                )}
                {user?.role === USER_ROLES.CANDIDATE && (
                  <ButtonPrimary
                    onClick={() =>
                      navigate(PATH_DASHBOARD.jobPosts.jobPostsOverview)
                    }
                  >
                    {t(FIND_JOB)}
                  </ButtonPrimary>
                )}
              </>
            )}
            {recruiterOrAdmin && (
              <ButtonPrimary sx={{ mx: 1 }} onClick={getApi}>
                {GET_URL}
              </ButtonPrimary>
            )}
            {!my_profile &&
              !isForCostumerProfile &&
              !(isCandidate || isDesigner || isEmployee) && (
                <ButtonPrimary
                  sx={{ mx: 1, minHeight: '100%' }}
                  onClick={() => openChatProfile()}
                >
                  {t('Chat')}
                </ButtonPrimary>
              )}
            {recruiterOrAdmin && !isForCostumerProfile && (
              <ButtonPrimary
                sx={{ mx: 1 }}
                onClick={() => setShowPDFModal(true)}
              >
                {t('Download PDF')}
              </ButtonPrimary>
            )}
          </Box>
        )}
        <ApproveDialog
          profileHeader={true}
          open={openDeleteDialog}
          title={t("Those you've shared the link with will no longer have access to the candidate's information. Are you sure you want to remove the link?")}
          onClose={() => setOpenDeleteDialog(false)}
          onApprove={handleDeleteURL}
        />
        {isBoolean && !isEmployee && !token ? (
          <div
            style={{
              marginTop: '30px',
              display: 'flex',
              alignItems: ' center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              columnGap: '40px'
            }}
          >
            <TextFieldUrl
              value={apiData.share_url}
              placeholder="Candidate url"
            />
            <ButtonPrimary onClick={generateApi}>{NEW_URL}</ButtonPrimary>
            {apiData?.share_url && (
              <>
                <ButtonPrimary onClick={handleOpenDeleteModal}>
                  {REMOVE_URL}
                </ButtonPrimary>

                <ButtonPrimary
                  href={
                    currentLang?.value == 'en'
                      ? `mailto:?subject=Share%20user%20URL&body=Dear%20colleague%0D%0A%0D%0APlease%20review%20this%20candidate%20profile%20and%20let%20me%20know%20what%20you%20think%3F%0D%0A%0D%0A${encodeURIComponent(
                          apiData.share_url
                        )}%0D%0A%0D%0AThank%20you!`
                      : `mailto:?subject=Deel%20gebruikers-URL&body=Beste%20collega%0D%0A%0D%0AZou%20je%20deze%20kandidaat%20willen%20evalueren%20en%20mij%20laten%20weten%20wat%20je%20ervan%20vindt%3F%0D%0A%0D%0A${encodeURIComponent(
                          apiData.share_url
                        )}%0D%0A%0D%0AAlvast%20bedankt!`
                  }
                  target="_blank"
                >
                  {SHARE_URL}
                </ButtonPrimary>
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </HeadingOverlay>
    </Heading>
  );
};

export default ProfileHeader;
